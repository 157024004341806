@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");
.ContactBanner {
  position: relative;
}
.ContactBanner .Navbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
.ContactBanner .Navbar .header {
  padding: 6px 90px;
}
.ContactBanner .Navbar .header .logo img {
  width: 100px;
}
@media all and (max-width: 1200px) {
  .ContactBanner .Navbar .header {
    padding: 6px 60px;
  }
}
@media all and (max-width: 992px) {
  .ContactBanner .Navbar .header {
    padding: 6px 40px;
  }
}
@media all and (max-width: 768px) {
  .ContactBanner .Navbar .header {
    padding: 6px 24px;
  }
}
@media all and (max-width: 576px) {
  .ContactBanner .Navbar .header {
    padding: 0 16px;
  }
  .ContactBanner .Navbar .header .logo img {
    width: 80px;
  }
}
.ContactBanner .Navbar .header .nav-menu .nav-links li a {
  color: rgb(255, 182, 182);
}
@media all and (max-width: 568px) {
  .ContactBanner .Navbar .header .nav-menu .nav-links li a {
    color: white;
  }
}
.ContactBanner .Navbar .header .nav-menu .menu-button label span {
  background: rgb(255, 182, 182);
}
.ContactBanner .Navbar .header.active .logo img {
  width: 100px;
}
.ContactBanner .Navbar .header.active .nav-menu .nav-links li a {
  color: brown;
}
@media all and (max-width: 568px) {
  .ContactBanner .Navbar .header.active .nav-menu .nav-links li a {
    color: white;
  }
}
.ContactBanner .Navbar .header.active .nav-menu .menu-button label span {
  background: #6b2026;
}
.ContactBanner .container img {
  width: 100%;
  height: 310px;
  object-fit: cover;
}
.ContactBanner .gradient {
  position: absolute;
  top: 0;
  height: 99%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.452) 49%, rgba(13, 13, 13, 0.6) 100%);
}
.ContactBanner .text h2 {
  color: rgb(241, 240, 240);
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 60px;
  z-index: 1;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
}
@media all and (max-width: 992px) {
  .ContactBanner .text h2 {
    font-size: 48px;
  }
}
@media all and (max-width: 768px) {
  .ContactBanner .text h2 {
    font-size: 38px;
  }
}
@media all and (max-width: 576px) {
  .ContactBanner .text h2 {
    font-size: 30px;
    white-space: nowrap;
  }
}