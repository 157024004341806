@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");
.Introsection {
  position: relative;
  background-color: #CA6971;
  z-index: 5;
}
.Introsection .wave-layer {
  aspect-ratio: 930/150;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: -40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../public/img/stacked-peaks-haikei4.svg");
}
@media all and (min-width: 1440px) {
  .Introsection .wave-layer {
    top: -80px;
  }
}
.Introsection .container {
  padding: 190px 120px 60px;
  /* .right-col animation styling */
}
.Introsection .container .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.Introsection .container .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .Introsection .container .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .Introsection .container .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.Introsection .container .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.Introsection .container .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .Introsection .container .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .Introsection .container .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
@media all and (max-width: 576px) {
  .Introsection .container .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(30%);
  }
  .Introsection .container .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
@media all and (min-width: 2000px) {
  .Introsection .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Introsection .container {
    padding: 150px 80px 60px;
  }
}
@media all and (max-width: 992px) {
  .Introsection .container {
    padding: 150px 50px 50px;
  }
}
@media all and (max-width: 768px) {
  .Introsection .container {
    padding: 150px 32px 50px;
  }
}
@media all and (max-width: 576px) {
  .Introsection .container {
    padding: 80px 16px 40px;
    flex-direction: column-reverse;
  }
}
.Introsection .container .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 576px) {
  .Introsection .container .row {
    flex-direction: column-reverse;
  }
}
.Introsection .container .row .left-col .img-container {
  position: relative;
}
.Introsection .container .row .left-col .img-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 99%;
  z-index: -1;
  background-color: #802e35;
}
.Introsection .container .row .left-col .img-container:hover img {
  right: 0;
  bottom: 0;
}
.Introsection .container .row .left-col .img-container img {
  width: 240px;
  position: relative;
  right: 20px;
  bottom: 16px;
  transition: all 0.2s;
}
@media all and (max-width: 992px) {
  .Introsection .container .row .left-col .img-container img {
    width: 200px;
  }
}
@media all and (max-width: 576px) {
  .Introsection .container .row .left-col .img-container img {
    width: 160px;
  }
}
.Introsection .container .row .right-col {
  width: 60%;
}
@media all and (max-width: 576px) {
  .Introsection .container .row .right-col {
    width: 100%;
    padding-bottom: 60px;
  }
}
.Introsection .container .row .right-col h2 {
  font-family: cooper;
  font-weight: 500;
  padding-bottom: 20px;
  font-size: 26px;
  color: #FFD64f;
}
@font-face {
  font-family: cooper;
  src: url("../../public/Fonts/Cooper Std Black.ttf");
}
@media all and (max-width: 992px) {
  .Introsection .container .row .right-col h2 {
    font-size: 20px;
  }
}
@media all and (max-width: 576px) {
  .Introsection .container .row .right-col h2 {
    font-size: 24px;
  }
}
.Introsection .container .row .right-col p {
  font-family: "Roboto", sans-serif;
  line-height: 150%;
  word-spacing: 2px;
  font-weight: 400;
  font-size: 18px;
  color: rgb(255, 255, 255);
}
@media all and (max-width: 992px) {
  .Introsection .container .row .right-col p {
    font-size: 16px;
  }
}
@media all and (max-width: 576px) {
  .Introsection .container .row .right-col p {
    font-size: 16px;
  }
}
.Introsection .container .row .right-col .button {
  margin-bottom: 30px;
}
@media all and (max-width: 576px) {
  .Introsection .container .row .right-col .button {
    display: flex;
    justify-content: center;
  }
}
.Introsection .container .row .right-col .button a {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
}
.Introsection .container .row .right-col .button a {
  display: inline-block;
  margin-top: 30px;
  border: 3px solid #802e35;
  outline: none;
  padding: 11px 16px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: 0.4s;
  z-index: 99;
}
@media all and (max-width: 768px) {
  .Introsection .container .row .right-col .button a {
    padding: 12px;
  }
}
.Introsection .container .row .right-col .button a:hover::before {
  transform: scale(1.5);
}
.Introsection .container .row .right-col .button a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: -1;
  background-color: #802e35;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  transform: scale(0);
}