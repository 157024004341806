@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");
.GetInTouch {
  position: relative;
  background-color: #CA6971;
  z-index: 5;
}
.GetInTouch .wave-layer {
  aspect-ratio: 930/150;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: -40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../../public/img/stacked-peaks-haikei4.svg");
}
@media all and (min-width: 1440px) {
  .GetInTouch .wave-layer {
    top: -80px;
  }
}
.GetInTouch .container {
  padding: 170px 120px 50px;
  /* .right-col animation styling */
}
.GetInTouch .container .custom-fade-left[data-aos=custom-fade-left] {
  opacity: 0;
  transform: translateX(100px);
}
.GetInTouch .container .custom-fade-left.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .GetInTouch .container .custom-fade-left[data-aos=custom-fade-left] {
    opacity: 0;
    transform: translateY(0);
  }
  .GetInTouch .container .custom-fade-left.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
.GetInTouch .container .custom-fade-right[data-aos=custom-fade-right] {
  opacity: 0;
  transform: translateX(-100px);
}
.GetInTouch .container .custom-fade-right.aos-animate {
  opacity: 1;
  transform: translateX(0);
}
@media all and (max-width: 576px) {
  .GetInTouch .container .custom-fade-right[data-aos=custom-fade-right] {
    opacity: 0;
    transform: translateY(0);
  }
  .GetInTouch .container .custom-fade-right.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
@media all and (max-width: 768px) {
  .GetInTouch .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media all and (min-width: 2000px) {
  .GetInTouch .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .GetInTouch .container {
    padding: 140px 80px 50px;
  }
}
@media all and (max-width: 992px) {
  .GetInTouch .container {
    padding: 100px 50px 50px;
  }
}
@media all and (max-width: 768px) {
  .GetInTouch .container {
    padding: 70px 32px 50px;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container {
    padding: 50px 16px 50px;
    flex-direction: column;
    justify-content: center;
  }
}
.GetInTouch .container h2 {
  background: -webkit-linear-gradient(180deg, rgb(255, 255, 255) 10%, rgb(255, 214, 79) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  text-align: center;
  padding-bottom: 96px;
  font-family: cooper;
  font-weight: 500;
  position: relative;
}
@font-face {
  font-family: cooper;
  src: url("../../public/Fonts/Cooper Std Black.ttf");
}
.GetInTouch .container h2::before {
  content: "";
  position: absolute;
  top: 60px;
  transform: translateX(-50%);
  left: 50%;
  width: 140px;
  height: 4px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(255, 246, 201, 0) 0%, rgb(255, 246, 201) 20%, rgb(248, 216, 109) 80%, rgba(248, 216, 109, 0) 100%);
}
@media all and (max-width: 992px) {
  .GetInTouch .container h2 {
    font-size: 30px;
    padding-bottom: 80px;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container h2 {
    padding-bottom: 50px;
  }
}
.GetInTouch .container .row {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row {
    flex-direction: column;
  }
}
.GetInTouch .container .row .col {
  padding: 0 6vw;
  text-align: left;
}
@media all and (max-width: 1200px) {
  .GetInTouch .container .row .col {
    padding: 0 4vw;
  }
}
@media all and (max-width: 992px) {
  .GetInTouch .container .row .col {
    padding: 0 3vw;
  }
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col {
    padding: 2vw 3vw;
    text-align: center;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col {
    padding: 2vw 0;
  }
}
.GetInTouch .container .row .col .text.kochi {
  margin-top: -10px;
}
.GetInTouch .container .row .col .text {
  display: flex;
  padding-bottom: 20px;
  margin-left: -8px;
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col .text {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col .text {
    padding-bottom: 26px;
  }
}
.GetInTouch .container .row .col .text span {
  padding-right: 16px;
  font-size: 18px;
  color: #802e35;
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col .text span {
    display: flex;
    justify-content: center;
    padding-right: 6px;
  }
}
.GetInTouch .container .row .col .text p {
  color: rgb(255, 242, 242);
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 150%;
  word-spacing: 2px;
  font-weight: 400;
  line-height: 130%;
}
.GetInTouch .container .row .col .text a {
  text-decoration: none;
  color: white;
  font-family: "Roboto", sans-serif;
  line-height: 150%;
  word-spacing: 2px;
  font-weight: 400;
}
.GetInTouch .container .row .col .text a:hover {
  text-decoration: underline;
}
.GetInTouch .container .row .col .text .phone-span {
  display: flex;
  padding-bottom: 10px;
}
.GetInTouch .container .row .col .text .phone-span i {
  padding-right: 10px;
}
.GetInTouch .container .row .col .text.flex-direction {
  display: flex;
  flex-direction: column;
}
.GetInTouch .container .row .col h3 {
  color: #802e35;
  font-size: 26px;
  padding-bottom: 5px;
  margin-left: 24px;
  font-family: cooper;
  font-weight: 500;
}
@font-face {
  font-family: cooper;
  src: url("../../public/Fonts/Cooper Std Black.ttf");
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col h3 {
    margin-left: 0;
  }
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col h3 {
    font-size: 20px;
  }
}
.GetInTouch .container .row .col.form form div {
  padding: 10px;
}
.GetInTouch .container .row .col.form form div input {
  height: 40px;
  width: 350px;
  color: white;
  padding: 5px;
  background-color: rgb(241, 151, 151);
  border: none;
  border: 1px solid rgb(128, 67, 67);
}
.GetInTouch .container .row .col.form form div input::placeholder {
  padding-left: 10px;
  color: white;
  font-weight: 500;
}
.GetInTouch .container .row .col.form form div input:focus {
  background-color: #CA6971;
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col.form form div input {
    width: 280px;
  }
}
@media all and (max-width: 378px) {
  .GetInTouch .container .row .col.form form div input {
    width: 200px;
  }
}
.GetInTouch .container .row .col.form form div textarea {
  height: 80px;
  width: 350px;
  color: white;
  background-color: rgb(241, 151, 151);
  border: none;
  border: 1px solid rgb(128, 67, 67);
  font-family: "Roboto", sans-serif;
  line-height: 150%;
  word-spacing: 2px;
  font-weight: 400;
}
.GetInTouch .container .row .col.form form div textarea::placeholder {
  padding-left: 10px;
  padding-top: 10px;
  font-weight: 500;
  color: white;
}
.GetInTouch .container .row .col.form form div textarea:focus {
  background-color: #CA6971;
}
@media all and (max-width: 576px) {
  .GetInTouch .container .row .col.form form div textarea {
    width: 280px;
  }
}
@media all and (max-width: 378px) {
  .GetInTouch .container .row .col.form form div textarea {
    width: 200px;
  }
}
.GetInTouch .container .row .col.form form button {
  background-color: #CA6971;
  padding: 10px 16px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-left: 10px;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  line-height: 150%;
  word-spacing: 2px;
  font-weight: 400;
}
.GetInTouch .container .row .col.form form button {
  display: inline-block;
  margin-top: 30px;
  border: 3px solid #802e35;
  outline: none;
  padding: 11px 16px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: 0.4s;
  z-index: 99;
}
@media all and (max-width: 768px) {
  .GetInTouch .container .row .col.form form button {
    padding: 12px;
  }
}
.GetInTouch .container .row .col.form form button:hover::before {
  transform: scale(1.5);
}
.GetInTouch .container .row .col.form form button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: -1;
  background-color: #802e35;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  transform: scale(0);
}
.GetInTouch .container .row .col.form form .successMessage {
  display: none;
}
.GetInTouch .container .row .col.form form .successMessage.active {
  display: block;
  margin-top: 14px;
  color: rgb(255, 255, 255);
  font-size: 17px;
  margin-left: 10px;
}
.GetInTouch .container .row .col.form form .errorMessage {
  display: none;
}
.GetInTouch .container .row .col.form form .errorMessage.active {
  display: block;
  margin-top: 14px;
  color: rgb(255, 74, 74);
  font-size: 16px;
}