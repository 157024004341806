@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");
.FeaturedProducts {
  position: relative;
  background-color: #CA6971;
  margin-top: -2px;
}
.FeaturedProducts h2 {
  background: -webkit-linear-gradient(180deg, rgb(255, 255, 255) 10%, rgb(255, 214, 79) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  text-align: center;
  padding-bottom: 36px;
  font-family: cooper;
  font-weight: 500;
}
@font-face {
  font-family: cooper;
  src: url("../../public/Fonts/Cooper Std Black.ttf");
}
.FeaturedProducts h2::before {
  content: "";
  position: absolute;
  top: 60px;
  transform: translateX(-50%);
  left: 50%;
  width: 180px;
  height: 4px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(255, 246, 201, 0) 0%, rgb(255, 246, 201) 20%, rgb(248, 216, 109) 80%, rgba(248, 216, 109, 0) 100%);
}
@media all and (max-width: 992px) {
  .FeaturedProducts h2 {
    font-size: 29px;
    padding-bottom: 30px;
  }
}
@media all and (max-width: 576px) {
  .FeaturedProducts h2 {
    padding-bottom: 30px;
  }
}
.FeaturedProducts .container {
  padding: 80px 120px;
}
@media all and (min-width: 2000px) {
  .FeaturedProducts .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .FeaturedProducts .container {
    padding: 80px 80px;
  }
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container {
    padding: 50px 50px;
  }
}
@media all and (max-width: 768px) {
  .FeaturedProducts .container {
    padding: 50px 32px;
  }
}
@media all and (max-width: 576px) {
  .FeaturedProducts .container {
    padding: 30px 16px 50px;
  }
}
.FeaturedProducts .container .category-buttons {
  padding-bottom: 36px;
  margin-left: 10px;
  display: flex;
}
@media all and (max-width: 576px) {
  .FeaturedProducts .container .category-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.FeaturedProducts .container .category-buttons button {
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: 600;
  margin-right: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
}
@media all and (max-width: 992px) {
  .FeaturedProducts .container .category-buttons button {
    font-size: 18px;
  }
}
@media all and (max-width: 576px) {
  .FeaturedProducts .container .category-buttons button {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}
.FeaturedProducts .container .category-buttons .active {
  color: rgb(155, 0, 0);
}
.FeaturedProducts .container .category-buttons .active:hover {
  cursor: pointer;
}
.FeaturedProducts .container .category-buttons .not-active {
  color: rgb(247, 247, 247);
}
.FeaturedProducts .container .category-buttons .not-active:hover {
  cursor: pointer;
  color: rgb(255, 228, 228);
}
.FeaturedProducts .container .card-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
  gap: 60px;
  justify-content: space-around;
}
@media all and (max-width: 768px) {
  .FeaturedProducts .container .card-container {
    justify-content: space-around;
  }
}
@media all and (max-width: 576px) {
  .FeaturedProducts .container .card-container {
    justify-content: center;
  }
}
.FeaturedProducts .container .card-container .card {
  width: 100%;
  max-width: 280px;
  margin: 10px;
  height: 100%;
  background-color: rgb(255, 182, 182);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  animation: zoom-out 400ms;
}
@keyframes zoom-out {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(100%);
  }
}
.FeaturedProducts .container .card-container .card .img-container {
  overflow: hidden;
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.FeaturedProducts .container .card-container .card .img-container img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: 0.3s all;
}
.FeaturedProducts .container .card-container .card:hover {
  transform: translateY(-3px);
  -webkit-box-shadow: 0px 11px 33px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 11px 33px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 11px 33px -12px rgba(0, 0, 0, 0.75);
  transition: 0.3s transform;
}
.FeaturedProducts .container .card-container .card:hover img {
  transform: scale(1.3);
}
.FeaturedProducts .container .card-container .card:hover .content h3 {
  color: #FFD64f;
}
.FeaturedProducts .container .card-container .card:hover .content p {
  color: white;
}
.FeaturedProducts .container .card-container .card:hover .content::before {
  transform: scale(1.46);
  opacity: 1;
  visibility: visible;
}
.FeaturedProducts .container .card-container .card .content {
  padding: 18px 10px;
  min-height: 100px;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 0 0 10px 10px;
}
.FeaturedProducts .container .card-container .card .content h3 {
  font-size: 22px;
  font-family: cooper;
  font-weight: 500;
  color: #802e35;
  margin-bottom: 8px;
}
@font-face {
  font-family: cooper;
  src: url("../../public/Fonts/Cooper Std Black.ttf");
}
.FeaturedProducts .container .card-container .card .content p {
  color: #802e35;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  opacity: 0.7;
}
.FeaturedProducts .container .card-container .card .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(135deg, rgb(128, 46, 53) 0%, rgb(128, 46, 53) 29%, rgb(128, 46, 53) 34%, rgb(202, 105, 113) 85%);
  border-radius: 100% 0% 100% 0%/0% 0% 100% 100%;
  transition: all 500ms ease-out;
  transform: scale(0);
  opacity: 0;
  transform-origin: left top;
}
.FeaturedProducts .container .button {
  text-align: center;
  margin-top: 80px;
}
.FeaturedProducts .container .button a {
  display: inline-block;
  margin-top: 30px;
  border: 3px solid #802e35;
  outline: none;
  padding: 11px 16px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: 0.4s;
  z-index: 99;
}
@media all and (max-width: 768px) {
  .FeaturedProducts .container .button a {
    padding: 12px;
  }
}
.FeaturedProducts .container .button a:hover::before {
  transform: scale(1.5);
}
.FeaturedProducts .container .button a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: -1;
  background-color: #802e35;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  transform: scale(0);
}