@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");
.Testimonials {
  position: relative;
  margin-top: -2px;
  position: relative;
  z-index: 1;
  background-color: #CA6971;
}
.Testimonials h2 {
  background: -webkit-linear-gradient(180deg, rgb(255, 255, 255) 10%, rgb(255, 214, 79) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  text-align: center;
  position: relative;
  padding-bottom: 80px;
  font-family: cooper;
  font-weight: 500;
}
@font-face {
  font-family: cooper;
  src: url("../../public/Fonts/Cooper Std Black.ttf");
}
.Testimonials h2::before {
  content: "";
  position: absolute;
  top: 60px;
  transform: translateX(-50%);
  left: 50%;
  width: 180px;
  height: 4px;
  border-radius: 10px;
  background: linear-gradient(90deg, rgba(255, 246, 201, 0) 0%, rgb(255, 246, 201) 20%, rgb(248, 216, 109) 80%, rgba(248, 216, 109, 0) 100%);
}
.Testimonials .container {
  padding: 50px 120px 160px;
}
@media all and (min-width: 2000px) {
  .Testimonials .container {
    max-width: 85%;
    margin: 0 auto;
  }
}
@media all and (max-width: 1200px) {
  .Testimonials .container {
    padding: 50px 80px;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container {
    padding: 50px 50px;
  }
}
@media all and (max-width: 768px) {
  .Testimonials .container {
    padding: 50px 32px;
  }
}
@media all and (max-width: 576px) {
  .Testimonials .container {
    padding: 50px 16px;
    flex-direction: column-reverse;
  }
}
.Testimonials .container .main-carousel {
  height: 100%;
}
.Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
  margin-left: -7%;
  background-color: whitesmoke;
  color: #802e35;
}
@media all and (min-width: 1800px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -4%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous {
    margin-left: -6%;
  }
}
.Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
  margin-right: -7%;
  background-color: whitesmoke;
  color: #802e35;
}
@media all and (min-width: 1800px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -4%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    margin-right: -6%;
  }
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    width: 36px;
    height: 36px;
  }
}
@media all and (max-width: 768px) {
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.previous,
  .Testimonials .container .main-carousel .flickity-button.flickity-prev-next-button.next {
    display: none;
  }
}
.Testimonials .container .main-carousel .carousel-cell {
  width: 33%;
  display: flex;
  min-height: 100%;
  justify-content: center;
  padding-bottom: 60px;
}
@media all and (max-width: 992px) {
  .Testimonials .container .main-carousel .carousel-cell {
    width: 50%;
  }
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .carousel-cell {
    width: 100%;
  }
}
.Testimonials .container .main-carousel .carousel-cell .card {
  background-color: rgb(255, 182, 182);
  padding: 20px 28px;
  border-radius: 6px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: 0.4s all;
  text-align: center;
  min-height: 370px;
  position: relative;
  z-index: 2;
}
.Testimonials .container .main-carousel .carousel-cell .card:hover {
  transform: translateY(-3px);
  -webkit-box-shadow: -1px 9px 13px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 9px 13px -6px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 9px 13px -6px rgba(0, 0, 0, 0.75);
}
.Testimonials .container .main-carousel .carousel-cell .card .quote {
  font-size: 100px;
  position: absolute;
  top: 0;
  z-index: -1;
}
.Testimonials .container .main-carousel .carousel-cell .card .quote i {
  font-size: 80px;
  color: rgba(255, 255, 255, 0.4392156863);
}
.Testimonials .container .main-carousel .carousel-cell .card .content {
  padding: 20px 0 14px;
}
.Testimonials .container .main-carousel .carousel-cell .card .content p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  line-height: 140%;
}
.Testimonials .container .main-carousel .carousel-cell .card h3 {
  font-size: 18px;
  font-weight: 400;
  color: #802e35;
  font-family: cooper;
  font-weight: 500;
}
@font-face {
  font-family: cooper;
  src: url("../../public/Fonts/Cooper Std Black.ttf");
}
.Testimonials .container .main-carousel .carousel-cell .card img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin: 6px 0;
}
.Testimonials .container .main-carousel .carousel-cell .card .five-star-icon i {
  font-size: 13px;
  margin-bottom: 6px;
  color: #FFD700;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
  margin-right: 1px;
}
.Testimonials .container .main-carousel .flickity-page-dots .dot {
  height: 6px;
  width: 30px;
  margin: 2px;
  border-radius: 0;
  transition: all 0.6s ease-in;
  background-color: #802e35;
}
@media all and (max-width: 568px) {
  .Testimonials .container .main-carousel .flickity-page-dots .dot {
    width: 14px;
    height: 6px;
  }
}