@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Kalam:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap");
.Map {
  position: relative;
}
.Map .container {
  display: flex;
  justify-content: center;
}
.Map .container button {
  padding: 14px;
  background-color: rgb(212, 209, 209);
  position: absolute;
  bottom: 2px;
  border: none;
  outline: none;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 17px;
  transition: all 0.3s;
}
.Map .container button:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
}
.Map .darkmode_map {
  filter: grayscale(96%) invert(100%);
}